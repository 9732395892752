<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <b-button variant="info" @click="createPins()">Создать Pin-коды</b-button>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
       <h4>Фильтры</h4>
      </div>
    </div>
    <div class="row">
      <div class="col-1">
        <strong>Статус активации</strong>
      </div>
      <div class="col-5">
        <b-radio-group v-model="printDateFilter" :options="printDateFilterOptions"></b-radio-group>
      </div>
      <div class="col-1">
        <strong>Статус регистрации</strong>
      </div>
      <div class="col-5">
        <b-radio-group v-model="clientFilter" :options="clientFilterOptions"></b-radio-group>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div v-if="error" class="alert alert-danger">
          {{error}}
        </div>
        <b-table striped hover :items="items | filterItems(printDateFilter, clientFilter)" :fields="fields" ref="measurementUnitTable">

          <template v-slot:cell(clientName)="data">
            <span>{{ data.item.firstname }} {{ data.item.middlename }} {{ data.item.lastname }} </span>
          </template>


          <template v-slot:cell(buttons)="data">
            <h3>
              <nobr>
                <b-link @click="activatePin(data.item)">
                  <b-icon variant="info" :icon="data.item.printDate ? 'toggle-on' : 'toggle-off'" size="lg"></b-icon>
                </b-link>
<!--                <b-link @click="editMeasurementUnit(data.item)" ><b-icon variant="info" icon="pencil" size="lg" ></b-icon></b-link>-->
                &nbsp;
                <b-link @click="deletePin(data.item)"><b-icon variant="info" icon="x-circle" size="lg" ></b-icon></b-link>
              </nobr>
            </h3>
          </template>
        </b-table>
        <b-modal id="pinModal" hide-footer size="lg" title="Создание пин-кодов">
          <b-form-group id="paramDefinition" label-for="paramDefinition" label="Укажите количество создаваемых пин-кодов">
            <b-form-input type="number" required v-model="createPinCount" :state="createPinCount > 0"></b-form-input>
          </b-form-group>
          <b-form-group id="paramDefinition" label-for="paramDefinition" label="Дни активации">
            <b-form-input type="number" required v-model="activationDays" :state="activationDays > 0"></b-form-input>
          </b-form-group>

          <b-form-group>
            <b-button variant="info" @click="submitPinCreate" :disabled="saving" >Сохранить <b-spinner v-if="saving" variant="light" small></b-spinner></b-button>
          </b-form-group>
        </b-modal>
        <Loader v-bind:showLoader="loading" />
      </div>
    </div>
  </div>

</template>

<script>
import {host} from "@/constants";

export default {
  name: "PinList",
  data() {
    return {
      createPinCount: 10,
      activationDays: 10,
      saving: false,
      printDateFilter: 0,
      printDateFilterOptions: [{value: 0, text: 'Все'},{value: 1, text: 'Неактивированные'}, {value: 2, text: 'Активированные'}],
      clientFilter: 0,
      clientFilterOptions: [{value: 0, text: 'Все'},{value: 1, text: 'Не использованные при регистрации'}, {value: 2, text: 'Использованные при регистрации'}],
      items:[],
      fields:[
        {
          key: 'pin',
          label: 'Pin-код',
          sortable: true
        },
        {
          key: 'createDate',
          label: 'Дата создания',
          sortable: true
        },
        {
          key: 'printDate',
          label: 'Дата активации',
          sortable: true
        },
        {
          key: 'activationDaysCount',
          label: 'Количество дней активации',
          sortable: true
        },
        {
          key: 'clientName',
          label: 'Применил клиент',
          sortable: true
        },
        {
          key: 'buttons',
          label: ' ',
          sortable: false
        }
      ]
    }
  },
  filters: {
    filterItems(items, printDateFilter, clientFilter) {
      if (printDateFilter != 0 && clientFilter != 0) {
        return items.filter(el => (
            (
                (!el.printDate && printDateFilter == 1) ||
                (el.printDate!= null && el.printDate != undefined && printDateFilter == 2)
            )
            &&
            (
                (!el.clientId && clientFilter == 1) ||
                (el.clientId!= null && el.clientId != undefined && clientFilter == 2)
            )
        ))
      } else if (printDateFilter != 0) {
        if (printDateFilter == 1) {
          return items.filter(el => (!el.printDate))
        } else if (printDateFilter == 2) {
          return items.filter(el => (el.printDate!= null && el.printDate != undefined))
        }
      } else if (clientFilter != 0) {
        if (clientFilter == 1) {
          return items.filter(el => (!el.clientId))
        } else if (clientFilter == 2) {
          return items.filter(el => (el.clientId!= null && el.clientId != undefined))
        }
      }

      return items
    }
  },
  methods: {
    activatePin(item) {
      if (!item.printDate) {
        fetch(host + '/rest/pins/' + item.pinId + '/activate' , {
          method:'PUT',
          credentials: 'include'
        })
            .then(response => response.json())
            .then(json => {
              if (json.resultCode != 0) {
                this.$errorToast('Ошибка активации пин-кода ' + json.resultCode + ' ' + json.message)
              } else {
                this.$messageToast('Успешная активация PIN-кода')
                this.fetchData()
              }
            }).catch(error => {
          this.$errorToast('Ошибка активации пин-кода ' + JSON.stringify(error))

        })
      }
    },
    selectPrintDateFilter() {

    },
    createPins(item = null) {
      this.currentMeasurementUnit = item
      this.$bvModal.show('pinModal')
    },
    submitPinCreate() {
      this.saving = true
      fetch(host + '/rest/pins/' + this.createPinCount + '/' + this.activationDays, {
        method:'PUT',
        credentials: 'include'
      })
          .then(response => response.json())
          .then(json => {
            if (json.resultCode != 0) {
              this.$errorToast('Ошибка генерация пин-кода ' + json.resultCode + ' ' + json.message)
            } else {
              this.$bvModal.hide('pinModal')
              this.fetchData()
            }
            this.saving = false

          }).catch(error => {
            this.$errorToast('Ошибка генерация пин-кода ' + JSON.stringify(error))
            this.saving = false
          })
    },
    fetchData() {
      fetch(host + '/rest/pins', {
        credentials: 'include'
      })
          .then(response => response.json())
          .then(json => {
            this.items = json.pins
          })
    },
    async deletePin(item) {

      if (confirm('Вы действительно хотите удалить PIN-код?')) {
        let response = await fetch(host + '/rest/pins/' + item.pinId , {
          method: 'DELETE',
          credentials: 'include',
        })
        let json = await response.json()
        try {
          if (json.resultCode == 0) {
            this.$messageToast('Удаление PIN-кода')
            await this.fetchData()

          } else {
            this.$errorToast(json.resultCode + ' ' + json.message)
          }
        } catch(error) {
          this.$errorToast(error)
        }
      }
    }
  },
  mounted() {
    this.fetchData()
  }
}
</script>

<style scoped>

</style>